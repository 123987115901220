import "./App.css";
import Home from "./components/Home/Home";
import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Footer/Footer";
import Services from "./components/Services/Services";
import Products from "./components/Products/Products";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Careers from "./components/Careers/Careers";
// import Casestudy from "./components/Casestudy/Casestudy";
import DemoApp from "./components/Demoapp/DemoApp";
import EmergingTech from "./components/EmergingTech/EmergingTech";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Terms from "./components/termsconditon/terms";
import PrivacyPolicy from "./components/privacypolicy/Privcypolicy";
import { useState } from "react";
import SingleService from "./components/SingleService";
import Resource from "./components/Resources/Resources";
import SingleCaseStudy from "./components/SingleCaseStudy";
// import { useEffect } from 'react/cjs/react.development';

function App() {
  const [filter, setFilter] = useState("");
  const [filterdata, setFilterData] = useState("");
  const [filterservices, setFilterServices] = useState("");
  const filterFn = (filters) => {
    setFilter(filters);
  };
  const filterFnData = (filterdata) => {
    setFilterData(filterdata);
  };
  const filterFnServ = (filterservices) => {
    setFilterServices(filterservices);
  };
  // useEffect(() => {
  //   filterFn(filter)
  //   filterFnData(filterdata)
  //   filterFnServ(filterservices)
  // }, [filter, filterdata, filterservices])
  const App = () => {
    let route = useRoutes([
      { path: "/", element: <Home /> },
      { path: "/navigation", element: <Navigation /> },
      { path: "/services", element: <Services filterFnServ={filterFnServ} /> },
      { path: "/footer", element: <Footer /> },
      { path: "/products", element: <Products filterFn={filterFn} /> },
      { path: "/about", element: <About /> },
      { path: "/resources", element: <Resource /> },
      { path: "/contact", element: <Contact /> },
      { path: "/careers", element: <Careers /> },
      // { path: "/casestudy", element: <Casestudy filter={filter} /> },
      {
        path: "/demoapp",
        element: (
          <DemoApp
            filter={filter}
            filterdata={filterdata}
            filterservices={filterservices}
          />
        ),
      },
      {
        path: "/emergingtech",
        element: (
          <EmergingTech
            filterFnData={filterFnData}
            filterFnServ={filterFnServ}
          />
        ),
      },
      { path: "/terms&conditions", element: <Terms /> },
      { path: "/privacypolicy", element: <PrivacyPolicy /> },
      {
        path: "/service1",
        element: (
          <SingleService
            title='AI/ML Solutions'
            description='We work with customers to develop bespoke AI solutions to accomplish
            their strategic business outcomes. We work across industries on
            solving complex problems using AI algorithms designed to handle
            structured as well as unstructured data. We have strong domain
            capabilities in classical Machine Learning as well as Deep Learning
            based algorithm development.'
            // coreDetails={[
            //   {
            //     coreTitle: "Predictive Analytics",
            //     coreDescription:
            //       "We develop custom AI solutions for predictive analytics such as Churn Prediction and Recommendation Systems,Predictive maintenance/ Forecasting for Manufacturing ,Smart city analytics (Crowd prediction, Surveillance).",
            //   },
            //   {
            //     coreTitle: "AI-based process automation",
            //     coreDescription:
            //       " We develop custom text and image/video analytics based solutions such as Document parsing, semantic analysis , Warranty/ Claims analytics ,Visual inspection/ defect detection in Manufacturing Car damage detection, Smart KYC, Signature detection.",
            //   },
            // ]}
            image='assets/images/Services-1.jpeg'
            flowchartDetails={[
              {
                flowchartImage: "assets/images/flowchart-5.png",
                flowchartTitle: "Predictive Analytics",
                flowchartSubtitle:"We develop custom AI solutions for Predictive Analytics such as Churn Prediction and Recommendation Systems, Predictive Maintenance/Forecasting for Manufacturing, Smart City Analytics (Crowd Prediction, Surveillance)."
              },
              {
                flowchartImage: "assets/images/flowchart-6.png",
                flowchartTitle: "AI-based Process Automation",
                flowchartSubtitle:"We develop custom text and Image/Video analytics based solutions such as Document Parsing, Semantic Analysis, Warranty/ Claims Analytics, Visual Inspection/Defect Detection in Manufacturing Car Damage Detection, Smart KYC, Signature Detection."
              },
            ]}
            valueDetails={[
              {
                value:
                  "Our analytics solution is enabling improved crowd management and facility utilization for a global tourist destination.",
              },
              {
                value:
                  "Reduced customer churn for an Auto insurance company by up to 16% using our recommendation engine.",
              },
              {
                value:
                  "5.7X projected gain in $ value of Basket Revenue as compared to recommending popular products using our AI Engine for a travel portal.",
              },
              {
                value:
                  "Reduced overall insurance claim processing time by 20% by implementing AI based smart inspection for car damage detection.",
              },
            ]}
          />
        ),
      },
      {
        path: "/service2",
        element: (
          <SingleService
            title='Cloud Deployments'
            description='We offer a comprehensive suite of cloud services that seamlessly integrate into diverse business environments. Our DevOps expertise ensures agile and efficient development processes, fostering collaboration between development and operations teams to accelerate the deployment of AI/ML models. With a strong focus on MLOps, we streamline the entire machine learning lifecycle, from model development to deployment and monitoring. Our FinOps solutions optimize cloud costs, ensuring clients maximize the value of their AI investments. Additionally, our SecOps practices prioritize robust security measures, safeguarding sensitive data and ensuring compliance with industry regulations.'
            image='assets/images/Services-2.jpeg'
            flowchartDetails={[
              {
                flowchartImage: "assets/images/flowchart-1.png",
                flowchartTitle: "DevOps",
                flowchartSubtitle:"Expertise in the implementation of DevOps practices, delivering end-to-end, scalable, and automated solutions that prioritize efficient development, continuous integration, and seamless deployment pipelines"
              },
              {
                flowchartImage: "assets/images/flowchart-2.png",
                flowchartTitle: "MLOps",
                flowchartSubtitle:"Expertise in MLOps that helps us deploy, maintain and track performance of AI/ML Models in Production Reliably and Efficiently. Also, automatically retrain models when required"
              },
              {
                flowchartImage: "assets/images/flowchart-3.png",
                flowchartTitle: "FinOps",
                flowchartSubtitle:"Expertise in Billing Optimizations and Cost Reductions in Cloud Deployments"
              },
              {
                flowchartImage: "assets/images/flowchart-4.png",
                flowchartTitle: "SecOps",
                flowchartSubtitle:"Expertise in ensuring integration of comprehensive security into operations. Our proactive threat mitigation and incident response strategies help protect infrastructure and data efficiently"
              },
            ]}
            // coreDetails={[
            //   {
            //     coreTitle: "DevOps",
            //     coreDescription:
            //       "Expertise in the implementation of DevOps practices, delivering end-to-end, scalable, and automated solutions that prioritize efficient development, continuous integration, and seamless deployment pipelines.",
            //   },
            //   {
            //     coreTitle: "MLOps",
            //     coreDescription:
            //       "Expertise in MLOps that helps us deploy, maintain and track performance of AI/ML Models in Production Reliably and Efficiently. Also, automatically retrain models when required",
            //   },
            //   {
            //     coreTitle: "FinOps",
            //     coreDescription:
            //       "Expertise in Billing Optimizations and Cost Reductions in Cloud Deployments",
            //   },
            //   {
            //     coreTitle: "SecOps",
            //     coreDescription:
            //       "Expertise in ensuring integration of comprehensive security into operations. Our proactive threat mitigation and incident response strategies help protect infrastructure and data efficiently",
            //   },
            // ]}
            valueDetails={[
              {
                value:
                  "Cloud services cost optimization for our SaaS based platform (AlgoFabric) by more than 34% in 3 months.",
              },
              {
                value:
                  "End-to-End-AI implementation on Cloud for “Lead Analytics” for a Large US Finserv Client",
              },
              {
                value:
                  "End-to-End-AI implementation on Cloud for a Smart City Project in a Foreign Government",
              },
              {
                value:
                  "Homegrown SaaS based platform (AlgoFabric) development and deployment on cloud",
              },
            ]}
          />
        ),
      },
      {
        path: "/service3",
        element: (
          <SingleService
            title='Generative AI'
            description='
            We have strong expertise in working on several LLM frameworks, such as OpenAI, Llama, and LangChain. We have developed technology accelerators built using the latest LLM models and frameworks. These accelerators can be used to quickly deploy GenAI solutions for multiple businesses. We also offer Gen AI solutions using cloud-based AI services such as Amazon BedRock and Azure OpenAI, among others. We are actively doing research on multi-modal GenAI models and building expertise to offer solutions using these cutting-edge technologies.'
            image='assets/images/Services-3.jpeg'
            iframeSrcLink = "https://www.youtube.com/embed/PnSIKurY9cs"
            flowchartDetails={[
              {
                flowchartImage: "assets/images/flowchart-7.png",
                flowchartTitle: "Solution Development and Deployment for Gen AI & LLM Applications",
                flowchartSubtitle:"We specialise in customised Gen AI-based solutions to automate complicated operations and boost corporate productivity using the latest innovations in the Gen AI space. We build innovative, intelligent, creative, and adaptable generative AI systems that offer a transformative approach to solving complex challenges.",
                flowchartStyle:"60%"
              },
              {
                flowchartImage: "assets/images/flowchart-8.png",
                flowchartTitle: "LLM based chatbot developments across business domains",
                flowchartSubtitle:"Expertise in LLM based Document Q&A chatbots for various domains, such as travel, healthcare, and finance etc. Integrate our chatbots with knowledge management systems that can access multiple documents and repositories.",
                flowchartStyle:"60%"
              },
              {
                flowchartImage: "assets/images/flowchart-9.png",
                flowchartTitle: "Gen AI & LLM Consulting and Solution Architectures",
                flowchartSubtitle:"Expertise in Gen AI Strategy and Consulting for Use Case Recognition and Feasibility Evaluation, Technology Assessment and Choice, Technical Architecting, AI Deployment based on your business's specific needs, goals, and challenges",
                flowchartStyle:"60%"
              },
            ]}
            valueDetails={[
              {
                value:
                  "Developed Process Automation solutions using LLM for a large Japanese company",
              },
              {
                value:
                  "Developed LLM based chatbots and customized document retrieval system",
              },
              {
                value:
                  "Built a Digital Twin of Human, based on LLM, that can do conversation ans answer questions on behalf of the human, for Japanese technology company.",
              },
              {
                value:
                  "Several LLM based tech accelerators offered on our Onestop.ai portal.",
              },
            ]}
            techStack={[
              {
                techImage: "assets/images/tech-stack-1.png",
              },
              {
                techImage: "assets/images/tech-stack-2.png",
              },
              {
                techImage: "assets/images/tech-stack-3.png",
              },
              {
                text: "Many More..",
              },
            ]}
          />
        ),
      },
      {
        path: "/caseStudy1",
        element: (
          <SingleCaseStudy
            title='Predictive Analytics in Financial Services'
            industry='Banking & Financial Services'
            technology='AI/ML Solutions'
            dataType='Text'
            description='Successfully provided customized Predictive Analytics solutions to a number of international clients in financial services.'
            image='assets/images/case-img1.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Predictive+Analytics.pdf'
          />
        ),
      },
      {
        path: "/caseStudy2",
        element: (
          <SingleCaseStudy
            title='Our Work on Recommender Systems'
            industry='Retail'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Used combination of multiple modeling techniques (matrix factorization and classification learning model) to identify best suited solution to recommend products from the product catalog based on the image of the original product.'
            image='assets/images/case-img2.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Our+work+on+Recommender+Systems.pdf'
          />
        ),
      },
      {
        path: "/caseStudy3",
        element: (
          <SingleCaseStudy
            title='Our Work in Legal Analytics'
            industry='Legal'
            technology='Deep Learning / AI'
            dataType='text'
            //description for legal analytics
            description='Automated/semi-automated manual labour to read and extract meaningful information like name, place, location, entity, dates, amounts etc. from legal contracts using Natural Language Processing (NLP).'
            image='assets/images/case-img3.png'
            link=' https://algo-case-studies.s3.ap-south-1.amazonaws.com/Legal+Analytics.pdf'
          />
        ),
      },
      {
        path: "/caseStudy4",
        element: (
          <SingleCaseStudy
            title='Automated Quality Control to Detect Defects in Painted Parts'
            industry='Manufacturing'
            technology='Deep Learning / AI'
            dataType='Image'
            description='Successfully developed a computer vision model that increased accuracy in identifying defects in painted parts, resulting in a significant improvement in quality control for our client.'
            image='assets/images/case-img4.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/DefectDetection.pdf'
          />
        ),
      },
      {
        path: "/caseStudy5",
        element: (
          <SingleCaseStudy
            title='Our AI solutions for E-Commerce'
            industry='Retail'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Take a look at our work in E-commerce Fashion AI where our innovative AI solutions helped our global retail clients enhance customer experiences.'
            image='assets/images/case-img5.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/E-commerce+solution+for+Retail.pdf'
          />
        ),
      },
      {
        path: "/caseStudy6",
        element: (
          <SingleCaseStudy
            title='Cooking up ML Recipes for a No/Low Code AI Platform'
            industry='Others'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Collaborated with the client to deliver end-to-end deployment of Machine Learning recipes for multiple use cases.'
            image='assets/images/case-img6.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Cooking+up+ML+recipes+for+a+NoLow+code+AI+platform.pdf'
          />
        ),
      },
      {
        path: "/caseStudy7",
        element: (
          <SingleCaseStudy
            title='Building an Automated Content Recommendation System'
            industry='Manufacturing'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Built a Hybrid content recommendation system for an innovative leadership development platform focusing on vertical development. Used a combination of collaborative and content based approach using Deep learning and ML techniques.'
            image='assets/images/case-img7.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/RecommendationSystem.pdf'
          />
        ),
      },
      {
        path: "/caseStudy8",
        element: (
          <SingleCaseStudy
            title='Revolutionizing Industries with Digital Twin Technology'
            industry='Manufacturing'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Dive into our case study on how our innovative approach to digital twins is revolutionizing industries. Discover how we are reshaping reality and maximizing efficiency one virtual replica at a time.'
            image='assets/images/case-img8.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Digital+Twin+Case+study.pdf'
          />
        ),
      }
    ]);
    return route;
  };

  return (
    <div className='App'>
      <Router>
        <App />
      </Router>
    </div>
  );
}

export default App;

// npm run build
// surge ./build --domain algoanalyticsweb.surge.sh
