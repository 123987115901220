import ButtonIconBorder from "./Common/ButtonIconBorder";
import React, { useState } from "react";

const IndustryData = [
  {
    title: "Banking & Finance",
    description:
      "Explore endless possibilities with the power of data driven AI/ML across multiple banking and finance functions",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img2.png",
  },
  {
    title: "Manufacturing",
    description:
      "Leverage our expertise in artificial intelligence, machine learning and cloud applied to manufacturing. Explore Industry 4.0.",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img1.png",
  },
  {
    title: "Legal",
    description:
      "Transform towards faster and cost effective legal services with AI/ML and Cloud services",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img3.png",
  },
  {
    title: "Retail",
    description:
      "Create unique experiences to enhance your customer's journey in today's digital world",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img4.png",
  },
  {
    title: "Healthcare",
    description:
      "Adopt the cutting edge AI approach for transformational healthcare ecosystems across the value chain",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img5.png",
  },
  {
    title: "Smart City",
    description:
      "Elevating Smart Cities through AI-powered Data Science Solutions",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img6.png",
  },
];

const Industry = (props) => {
  const [activeItem, setActiveItem] = useState(0);
  

  return (
    <div className='industry-section'>
      <div className='container'>
        <div className='common-page-main-section'>
          <div className='common-page-hero-title'>Industries We Serve</div>
          <div className='common-page-hero-subtitle'>
            Enhance your decision making capabilities with powerful insights and
            augmented intelligence with expertise in AI.
          </div>
        </div>

        <div className='industry-container'>
          <div className='industry-header'>
            {/* horizontal list of industry names */}
            <div className='industry-header-list'>
              {IndustryData.map((item, index) => {
                return (
                  <div
                    className={`industry-header-item ${
                      index === activeItem ? "active" : ""
                    }`}
                    onClick={() => setActiveItem(index)}>
                    {item.title}
                  </div>
                );
              })}
            </div>
          </div>
          <div className='industry-body'>
            {/* body contain industry title, description and 2 buttons on left side and image on right side */}
            {IndustryData.map((item, index) => {
              return (
                <div
                  className={`industry-body-item ${
                    index === activeItem ? "active" : ""
                  }`}>
                  <div className='industry-body-text'>
                    <div className='industry-body-title'>{item.title}</div>
                    <div className='industry-body-description'>
                      {item.description}
                    </div>
                    <div className='industry-body-buttons'>
                      <div className='industry-body-button'>
                        <a href={item.link}
                        // onClick={() => {
                        //   props.filterFn(item.title)
                        // }}
                        >
                          <ButtonIconBorder
                            name='Try Demo'
                            svgPathAttributes={{
                              fill: "currentColor",
                              d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                            }}
                            // styleClasses='justify-start'
                          />
                        </a>
                      </div>
                      <div className='industry-body-button'>
                        <a href={item.CaseStudyLink}>
                          <ButtonIconBorder
                            name='View Case Studies'
                            svgPathAttributes={{
                              fill: "currentColor",
                              d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                            }}
                            // styleClasses='justify-start'
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='industry-body-image'>
                    <img
                      src={item.image}
                      alt='industry img'
                      className='industry-image'
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industry;
