// import React, { useState, useEffect } from "react";

const OurStrength = () => {
  // const [activeItem, setActiveItem] = useState(0);
  // const [arrowDirection, setArrowDirection] = useState([]);
  // const handleClick = (index) => {
  //   setActiveItem(index);
  //   setArrowDirection((prevDirection) => {
  //     const newDirection = Array(prevDirection.length).fill("down");
  //     newDirection[index] = "up";
  //     return newDirection;
  //   });
  // };
  // const downArrowPath = "M1 1L5 5L9 1";
  // const upArrowPath = "M9 5L5 1L1 5";

  const listItems = [
    {
      title: "Founded on strong knowledge of Mathematics, Statistics and Algorithms",
      // subtitle: "",
    },
    {
      title: "Core team consists of experts in AI/ML",
      // subtitle: "",
    },
    {
      title: "Expertise from classical Machine Learning to Deep Learning to Latest Generative AI",
      // subtitle:
      //   "",
    },
    {
      title: "A strong panel of domain experts with expertise in each of the domains we work in",
      // subtitle:
      //   "",
    },
    {
      title: "More than 5 years of history of successful AI/ML Projects across the world!",
      // subtitle:
      //   "",
    },
  ];

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setActiveItem((prevActiveItem) => {
  //       const nextActiveItem = (prevActiveItem + 1) % listItems.length;
  //       setArrowDirection((prevDirection) => {
  //         const newDirection = Array(prevDirection.length).fill("down");
  //         newDirection[nextActiveItem] = "up";
  //         return newDirection;
  //       });
  //       return nextActiveItem;
  //     });
  //   }, 7000); // change active item every 3000ms (3 seconds)

  //   return () => clearInterval(intervalId); // cleanup on unmount
  // }, [listItems.length]);

  return (
    <div className='hero-section serv-page-hero serv-bg' id=''>
      <div className='container h-100'>
        <div className='common-page-main-section' style={{ padding: "2rem" }}>
          <div className='common-page-hero-title'>Our Strengths</div>
          <div className='common-page-hero-subtitle'>
            We are a specialist Data Analytics company focused on building AI/ML
            powered solutions to solve crucial business problems for customers
            across industries.
          </div>
        </div>
        <div className='row d-flex align-items-center hero-inner justify-content-between h-50'>
          <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 serv-left'>
            <div className='text-center'>
              <img
                src='assets/images/serv-hero-image.png'
                alt='hero'
                className='item-image'
              />
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 serv-right'>
            <div className='serv-list'>
              {/* why choose us list */}
              {listItems.map((item, index) => (
                <div className='serv-list-item'>
                  {/* className={`serv-list-item ${
                    index === activeItem ? "active" : ""
                  }`}> */}
                  <div className='serv-list-item-title'>
                    <img src='assets/images/bullseye.png' width={30} height={30} alt='arrow' />
                    <div style={{marginTop:"1rem"}}>{item.title}</div>
                    {/* svg for down arrow */}
                    {/* <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='10'
                      height='6'
                      viewBox='0 0 10 6'
                      fill='none'
                      onClick={() => handleClick(index)}>
                      <path
                        d={
                          arrowDirection[index] === "down"
                            ? downArrowPath
                            : upArrowPath
                        }
                        stroke='#2D4890'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg> */}
                  </div>
                  {/* {index === activeItem && (
                    <div className='serv-list-item-subtitle'>
                      {item.subtitle}
                    </div>
                  )} */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStrength;
