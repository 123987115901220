import { Fragment, useEffect } from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import $ from "jquery";
import React from "react";
import ButtonIconBorder from "../Common/ButtonIconBorder";
import ContactUs from "../Common/ContactUs";
import { Helmet } from "react-helmet";

const Services = () => {
  useEffect(() => {
    $(".loader").slideUp("slow");
    $("body").addClass("animate");
  }, []);

  const servicesData = [
    {
      title: "Cloud Deployments",
      text:"We have expertise in end-to-end AI/ML solution deployments in the cloud with successful deployments for large enterprises. Our expertise and offerings include:",
      point: {
        point1:
          "MLOps: Streamline the process of productionizing, maintaining and monitoring AI/ML Solutions, automated deployments and model updates",
        point2:
          "DevOps: Improve the Productivity and efficiency of your organization by automating key tasks in code development and deployments, in line with the industry best practices",
        point3:
          "FinOps: Save money, track your spending, and optimize deployments to minimize cloud expense",
        point4:
          "SecOps: Protect your environment and enable compliance with unified configuration and activity monitoring",
      },
      image: "assets/images/Services-2.jpeg",
      imageOnLeft: true,
      button: {
        name: "Learn More",
        link: "/service2",
      },
    },
    {
      title: "AI/ML solutions",
      text: "AI/ ML solutions spanning across industries and data modalities such as",
      point: {
        point1: "Text Analytics: Using Natural Language Processing (NLP) techniques to derive insights from data in text format",
        point2: "Image/ Video Analytics: Using Deep Learning techniques to analyze data in image/video format for business applications",
        point3: "Numerical Data Analytics: Using a combination of classical ML and Deep Learning to derive insights from numerical data",
        point4: "Sound Analytics: Using sophisticated AI techniques to extract meaningful information from sound data",
      },
      image: "assets/images/Services-1.jpeg",
      imageOnLeft: false,
      button: {
        name: "Learn More",
        link: "/service1",
      },
    },
    {
      title: "Generative AI",
      point: {
        point1: "Foundation Models/ Large Language Models(LLM) based solutions",
        point2:
          "Gen AI solutions using cloud based AI services such as Amazon BedRock and Azure OpenAI",
        point3: "Multi-modal Gen AI",
        point4: "Suite of LLM accelerators for multiple business applications",
      },
      image: "assets/images/Services-3.jpeg",
      imageOnLeft: true,
      button: {
        name: "Learn More",
        link: "/service3",
      },
    },
  ];
  return (
    <Fragment>
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Data Analytics Services - AlgoAnalytics</title>
        <meta
          name="description"
          content="Explore our comprehensive Data Analytics services, including Cloud Deployments, AI/ML Solutions, and Generative AI."
        />
        <meta
          name="keywords"
          content="Data Analytics, Cloud Deployments, AI/ML Solutions, Generative AI"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className='loader'>
        <div className='loader-inner'>
          <img
            src='assets/images/loader.gif'
            alt='Loader GIF'
            className='loader-img'
            loop='infinite'
          />
        </div>
      </div>
      <Navigation />
      {/* <!-- Hero Section Start  --> */}
      <div className='common-main-section serv-page-hero serv-bg' id='services'>
        <div className='container h-100'>
          <div className='common-page-hero-section'>
            <div className='common-page-hero-title'>Our Services</div>
            <div className='common-page-hero-subtitle'>
              We provide services across industries with more than 100 AI
              solutions implemented across the globe.
            </div>
          </div>

          {servicesData.map((data) => {
            return (
              <div className='item-grid' style={{paddingTop:"3rem",}} id={data.title}>
                {/* Image */}
                <div
                  className={`${
                    data.imageOnLeft === false ? "image-order" : ""
                  }`}>
                  <img
                    src={data.image}
                    alt='service'
                    className='item-image object-cover opacity-1'
                  />
                </div>
                {/* Text section */}
                <div className='item-text h-full py-4 text-left mx-8 '>
                  {/* title part */}
                  <div className='item-heading'>
                    <div className='item-title mb-9 '>{data.title}</div>
                  </div>
                  <div className='item-subtitle mb-9'>{data.text}</div>
                  {/* text part */}
                  <div className='mb-9 opacity-70'>
                    <ul>
                      {Object.values(data.point).map((point, index) => (
                        <li key={index} className='main-list'>
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* Button part */}
                  <a href={data.button.link}>
                    <ButtonIconBorder
                      name={data.button.name}
                      svgPathAttributes={{
                        fill: "currentColor",
                        d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                      }}
                      styleClasses='justify-start'
                    />
                  </a>
                </div>
              </div>
            );
          })}
          {/* <!-- Container End--> */}
        </div>
      </div>
      
      <ContactUs />
      <Footer />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className='back-top' href='#'>
        <i className='fa fa-angle-up'></i>
      </a>
    </Fragment>
  );
};
export default Services;
