/* eslint-disable eqeqeq */
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Fragment} from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import { Component } from "react";
import $ from "jquery";
import URLS from "../../constants";
import OurStrength from "../OurStrengthSection";
import ButtonIconBorder from "../Common/ButtonIconBorder";
// import { Link } from "react-router-dom";
import Industry from "../IndustrySection";
import Resources from "../ResourceSection";
import ContactUs from "../Common/ContactUs";
import LandingPage from "./LandingPage";

class Home extends Component {
  state = {
    customerlist: [],
    caseStudies: [],
    annoucement: "",
    url: "",
    count: 0,
    videoClicked: false,
  };

  getCustomerList = () => {
    // api.customer
    // .getCustomer()
    // .then((result) => {
    //     // console.log("get customer ", result.data);

    //     if (result.status) {
    //         this.calculateData(result.data);
    //     }
    // })
    // .catch((error) => {
    //     console.log(error);
    // });

    let URL = URLS.getCustomers;
    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        this.calculateData(data);
      });
  };

  getCaseStudies = () => {
    // api.caseStudy
    // .getCaseStudy()
    // .then((result) => {
    //     // console.log("get case study ", result);
    //     if (result.status == '1') {
    //         // console.log("get case study data", result.data);
    //         // setCaseStudies(result.data);
    //         this.setState({ caseStudies: result.data });
    //     }
    // })
    // .catch((error) => {
    //     console.log(error);
    // });
    let URL = URLS.getCaseStudies;
    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          caseStudies: data,
        });
      });
  };

  getAnnoucement = () => {
    // api.caseStudy
    // .getAnnoucement()
    // .then((result) => {
    //     // console.log("get annoucement ", result);
    //     if (result.status == '1') {
    //         // console.log("get case study data", result.data);
    //         if (result.data.length > 0) {
    //             if (result.data[0].marquee_status == 'active') {
    //                 this.setState({ annoucement: result.data[0].marquee_text });
    //                 this.setState({ url: result.data[0].marquee_url });

    //             }
    //         }
    //     }
    // })
    // .catch((error) => {
    //     console.log(error);
    // });
    let URL = URLS.getmarquees;
    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          annoucement: data[0].marquee_text,
          url: data[0].marquee_url,
        });
      });
  };

  calculateData = (arr) => {
    var perChunk = 9; // items per chunk

    var result = arr.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    // console.log('result',result);
    this.setState({ customerlist: result });
  };

  componentDidMount() {
    this.getCaseStudies();
    this.getCustomerList();
    this.getAnnoucement();
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  goDown = () => {
    let countVal = Number(this.state.count) + 1;
    this.setState({ count: countVal });
    var windowScroll = $("body").scrollTop();
    console.log("window scrolltop", windowScroll);
    console.log("countval", countVal);

    if (countVal == "1") {
      $("body").animate({ scrollTop: 617 }, "slow");
    } else if (countVal == "2") {
      $("body").animate({ scrollTop: 1100 }, "slow");
    } else if (countVal == "3") {
      $("body").animate({ scrollTop: 1550 }, "slow");
    } else if (countVal == "4") {
      $("body").animate({ scrollTop: 2092 }, "slow");
    } else if (countVal == "5") {
      $("body").animate({ scrollTop: 2852 }, "slow");
    } else if (countVal == "6") {
      $("body").animate({ scrollTop: 3634 }, "slow");
    } else if (countVal == "7") {
      $("body").animate({ scrollTop: 4368 }, "slow");
    } else if (countVal == "8") {
      $("body").animate({ scrollTop: 5116 }, "slow");
    } else {
      let scroll = Number($("#scrollTop").val());
      // console.log('scroll',scroll);
      if (scroll < 300) {
        this.setState({ count: 0 });
      }
      $("body").animate({ scrollTop: 4000 }, "slow");
    }

    // alert('ok');
  };
  //function to handle video click
  handleVideoClick = () => {
    this.setState({ videoClicked: true });
    console.log("video clicked");
  };

  CardData = [
    {
      title: "Cloud Deployments",
      subtitle:
        "Expertise in end-to-end cloud deployments of AI/ML using ML Ops, DevOps, FinOps, SecOps.",
      image: "assets/images/serv-2.png",
      link: "/service2",
    },
    {
      title: "AI/ML Solutions",
      subtitle:
        "AI based solutions spanning across industries and  data modalities (Text, Image, Video, Numerical)",
      image: "assets/images/serv-1.png",
      link: "/service1",
    },
    {
      title: "Generative AI",
      subtitle:
        "Develop custom business solutions built using open-source and closed-source Generative AI technologies. ",
      image: "assets/images/serv-3.png",
      link: "/service3",
    },
  ];

  ClientData = [
    {
      customerLogo: "assets/images/ClientLogo/logo_1.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_2.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_3.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_4.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_5.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_6.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_7.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_8.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_9.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_10.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_11.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_12.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_13.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_14.png",
    },
    // Intel logo , copyright
    // {
    //   customerLogo: "assets/images/logo/logo_img5.png",
    // },
    {
      customerLogo: "assets/images/ClientLogo/logo_15.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_16.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_17.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_18.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_19.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_20.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_21.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_22.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_23.png",
    },
    {
      customerLogo: "assets/images/ClientLogo/logo_24.png",
    },
  ];

  TechPartnerData = [
    {
      partnerLogo: "assets/images/logo/logo-img1.png",
      padding: "20%",
    },
    {
      partnerLogo: "assets/images/logo/logo-img2.png",
    },
    {
      partnerLogo: "assets/images/logo/logo-img3.png",
    },
    // Intel logo , copyright
    // {
    //   partnerLogo: "assets/images/logo/logo_img5.png",
    // },
    {
      partnerLogo: "assets/images/logo/logo_img6.png",
    },
    {
      partnerLogo: "assets/images/logo/logo_img7.png",
    },
    {
      partnerLogo: "assets/images/logo/logo_img8.png",
    },
  ];

  render() {
    // var settings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    // };

    return (
      <Fragment>
        <div className='loader'>
          <div className='loader-inner'>
            <img
              src='assets/images/loader.gif'
              alt='Loader GIF'
              className='loader-img'
              loop='infinite'
            />
          </div>
        </div>
        <input type='hidden' id='scrollTop' value='0' />
        <Navigation />
        {/* <!-- Hero Section Start  --> */}
        <div
          className='main-hero-section serv-page-hero hero-main section-main scroll-sec
          homepage-hero-section'
          id='hero-section'>
          <LandingPage />
          {/* <!-- Container End--> */}
        </div>
        <div className='go-down next' onClick={this.goDown} id='go-down'>
          <span>
            <i className='fa fa-angle-down'></i>
          </span>
        </div>
        {/* <!-- Hero Section End  --> */}

        {/* <!-- partner Section Start  --> */}
        <div className='partner-section section-main scroll-sec' id='partner'>
          <div className='partner-top'>
            <h3 className='small-heading text-center'>Our Clients</h3>
            <div className='client-logo-grid'>
              {this.ClientData &&
                this.ClientData.map((item, index) => {
                  return (
                    <div className='client-grid-item'>
                      <div className='logo-main' key={index}>
                        <img
                          src={item.customerLogo}
                          className='logo-image'
                          alt='client-logo'
                        />
                      </div>
                    </div>
                  );
                })}
              {/* {this.state.customerlist &&
                this.state.customerlist.map((data) => {
                  return (
                    data &&
                    data.map((item, index2) => (
                      <div className='client-grid-item'>
                        <a href='/' className='logo-main' key={index2}>
                          <img
                            src={item.customer_logo}
                            className='img-fluid'
                            alt=''
                          />
                        </a>
                      </div>
                    ))
                  );
                })} */}
            </div>
          </div>
          <div className='partner-middle'>
            <h3 className='small-heading text-center'>Our Tech Partners</h3>
            <div className='tech-logo-grid'>
              {this.TechPartnerData &&
                this.TechPartnerData.map((item, index) => {
                  return (
                    <>
                      <div href='/' className='tech-logo' key={index}>
                        <img
                          src={item.partnerLogo}
                          className='img-fluid'
                          alt='tech-partner-img'
                          style={{ padding: item.padding }}
                        />
                      </div>
                      {/* <div className='logo-divider'></div> */}
                    </>
                  );
                })}
            </div>
          </div>
          <div className='partner-bottom '>
            <h3 className='small-heading text-center'>
              Awards, Recognitions, and Patents
            </h3>
            <div className='logo-container d-flex justify-content-center'>
              <div className='logo-award'>
                <img
                  src='assets/images/logo/logo-img14.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <div className='logo-award'>
                <img
                  src='assets/images/logo/logo-img15.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <div className='logo-award'>
                <img
                  src='assets/images/logo/logo-img16.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <div className='logo-award'>
                <img
                  src='assets/images/logo/logo-img17.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- partner Section End  --> */}

        {/* services section start */}
        <div className='hero-section serv-page-hero serv-bg' id=''>
          <div className='container h-100'>
            <div
              className='common-page-main-section'
              style={{ padding: "2rem" }}>
              <div className='common-page-hero-title'>Our Services</div>
              <div className='common-page-hero-subtitle'>
                At AlgoAnalytics, we stand apart in the dynamic landscape of
                MLOps, DataOps, Data Analytics/AI R&D, and Business
                Intelligence.
              </div>
            </div>
            {/* service cards */}
            <div className='service-card-container'>
              {this.CardData.map((item) => {
                return (
                  <div className='service-card'>
                    <div className='service-card-img'>
                      <img
                        src={item.image}
                        className='img-fluid'
                        alt='serv-img'
                      />
                    </div>
                    <div className='service-card-title'>{item.title}</div>
                    <div className='service-card-subtitle'>{item.subtitle}</div>
                    <a href={item.link}>
                      <ButtonIconBorder
                        name='Learn more'
                        svgPathAttributes={{
                          fill: "currentColor",
                          d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                        }}
                        // styleClasses='justify-start'
                      />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/*services section end */}
        <Industry />
        <OurStrength />
        <Resources />
        <ContactUs />
        <Footer />
      </Fragment>
    );
  }
}
export default Home;
